@media print {
  .tab-content > .tab-pane {
    opacity: 1 !important;
    padding: 20px;
  }

  [data-aos] {
    visibility: visible !important;
    opacity: 1 !important;
  }
}
