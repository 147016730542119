.hai-accordion {
  .text-block {
    padding-right: 3rem;

    .text-class {
      font-size: 0.875rem;
      color: #003087;
      font-weight: bold;
    }
  }

  .accordion-block {

    .accordion-item .accordion-button {
      width: 100%;
      border:none;
      padding: 0;
    }

    .plus-icon g {
      margin-right: 0.5rem;
      fill: white;
    }

    .paragraph-class {
      border-bottom: 1px solid #EEEEEE;
      padding: 2rem 2rem 1.5rem 6rem;
      border-left: 4px solid #006eb5;


      br {
        display: block;
        content: "";
        height: 1.5rem;
      }
    }

    .btn-accordion-block {
      text-transform: uppercase;
      margin-bottom: 1rem;
      color: $indigo;
      position: relative;
      display: flex;
      gap: 1rem;
      align-items: center;
      cursor: pointer;
      font-size: 14px;
      background: transparent;
      border: none;

    }
    .button-block {
      gap: 8rem;
      .buttonContent {
        color: #003087;
        font-size: 0.75rem;
        gap: 0.5rem;
        align-items: center;
        justify-content: center;
        width: 13rem;
        @include mq(540) {
        }
        .arrow {
          height: 14px;
          width: 14px;
        }
      }
    }
    .list-item {
      width: 100%;
      display: flex;
      gap: 0.5rem;
      align-items: center;
      margin-bottom: 1.4rem;

      .icon {
        width: 14px;
      }

      svg {
        height: 14px;
        width: 14px;

        g {
          stroke: #003087;
          fill: #003087;
        }
      }
    }
  }
}