.navbar-top-vp .dropdown-toggle,
.navbar-light .dropdown-toggle {
  &::after {
    display: inline-block;
    width: 0.5em;
    height: 0.5em;
    margin: -0.3em 0 0 0.4em;
    vertical-align: middle;
    content: "";
    border: 0.3em solid;
    border-width: 0 0.15em 0.15em 0;
    transform: rotateZ(45deg);
  }
}

.navbar-top-vp .navbar-nav .show,
.navbar-light .navbar-nav .show {
  .dropdown-toggle {
    &::after {
      transform: rotateZ(225deg);
      vertical-align: baseline;
    }
  }
}

.navbar-light .navbar-nav .show {
  .dropdown-toggle {
    &::after {
      border-color: $indigo;
    }
  }
}

.navbar-dark .dropdown.show {
  @include mq(1281) {
    .dropdown-toggle {
      &::after {
        border-color: $white;
      }
    }
  }
}

.navbar-light .navbar-nav .show {
  & > .nav-link {
    color: $indigo;

    @include mq(1281) {
      color: $blue;
    }
  }
}

.navbar-dark .dropdown.show {
  & > .nav-link {
    color: $white;
  }
}

.dropdown-light-vp .dropdown-menu-bordered-vp {
  @include mq(768) {
    box-shadow: $box-shadow;
  }

  animation: fadein 0.4s;

  &:before {
    width: 1px;
    background: $gray-500;
    content: "";
    position: absolute;
    left: 1rem;
    top: calc(#{$dropdown-item-padding-y} * 2 + #{$dropdown-padding-y});
    bottom: calc(#{$dropdown-item-padding-y} + #{$dropdown-padding-y});
  }

  .dropdown-item {
    font-weight: $font-weight-bolder;
    font-size: $font-size-sm;
    transform: translateX(0px);
    transition: $btn-transition;

    &:hover,
    &:focus {
      @include mq(768) {
        transform: translateX(5px);
      }
    }
  }
}

.dropdown-menu {
  border: none;
}

.dropdown-menu-mega-vp li {
  position: static;
}

.dropdown-menu-mega-vp {
  position: absolute;
  width: 100%;
  left: 0;
  right: 0;
  padding: 1.3rem 0 0;
  margin-top: -1px;
  box-shadow: none;
  z-index: $zindex-tooltip;
  animation: fadein 0.4s;

  @include mq(1281) {
    padding: 2.25rem 0 3.375rem;
    box-shadow: $box-shadow;
  }

  @keyframes fadein {
    from {
      opacity: 0;
    }
    to {
      opacity: 1;
    }
  }

  .list-group:before {
    background: rgba($white, 0.3);
    left: 0;
    top: calc(#{$dropdown-item-padding-y} * 2);
    bottom: calc(#{$dropdown-item-padding-y} * 2);
  }

  .list-group-item:focus {
    color: $white;
  }

  .dropdown-item {
    font-size: $font-size-base;
  }

  h5 {
    text-transform: uppercase;
    font-weight: $font-weight-normal;
  }

  .nav-link {
    max-width: none;
  }

  .list-group-item.active,
  .list-group-item:hover {
    color: $white;
  }

  h1,
  h2,
  h3,
  h4,
  h5,
  h6,
  p,
  li,
  span,
  input,
  a {
    color: $white;
  }
}

.menu-col-vp {
  margin-bottom: 2rem;
}

.navbar-nav .dropdown-menu {
  position: relative;

  @include mq(1281) {
    position: static;
  }
}

.navbar-expand-lg .navbar-nav .dropdown-menu {
  @include mq(992) {
    overflow-y: auto;
    max-height: 100vh;
  }

  @include mq(1280, max) {
    position: relative;
  }
}
