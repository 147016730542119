// Headings

h1,
.h1 {
  @include mq(767, max) {
    font-size: 1.5rem;
  }
}

h1,
.h1,
h2,
.h2,
h3,
.h3 {
  font-weight: $font-weight-light;
}

h5,
.h5,
.btn:not(.btn-sm) {
  letter-spacing: 1px;
  line-height: 1.375rem;
  text-transform: uppercase;
}

.btn:not(.btn-sm) {
  line-height: 1.31rem;
}

a:hover,
a:focus {
  transition: $btn-transition;
}

.vp-date {
  font-size: $font-size-sm;
  letter-spacing: 1px;
}

// Lists

.list-unstyled {
  @include list-unstyled;
}

.list-inline {
  @include list-unstyled;
}

.list-inline-item {
  display: inline-block;

  &:not(:last-child) {
    margin-right: $list-inline-padding;
  }
}

.lead {
  color: $blue;
  margin-top: 0;
  margin-bottom: 2rem;
}

a {
  font-weight: $font-weight-bolder;
}

.list-vp li {
  margin-bottom: 1.4rem;
}

ul.list-bullet-vp,
ul.list-tick-vp,
.vp-content ul {
  padding-left: 0;

  li {
    padding-left: 2rem;
    position: relative;
    margin-bottom: 2rem;
    list-style: none;

    &::before {
      content: "";
      display: inline-block;
      height: 1em;
      position: absolute;
      width: 1em;
      background-size: contain;
      left: 0;
      top: 0;
    }
  }
}

ul.list-tick-vp li::before {
  background-repeat: no-repeat;
  background-image: url("data:image/svg+xml,%3C%3Fxml version='1.0' encoding='UTF-8'%3F%3E%3Csvg width='14px' height='11px' viewBox='0 0 14 11' version='1.1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink'%3E%3C!-- Generator: Sketch 51.1 (57501) - http://www.bohemiancoding.com/sketch --%3E%3Ctitle%3Echeck%3C/title%3E%3Cdesc%3ECreated with Sketch.%3C/desc%3E%3Cdefs%3E%3C/defs%3E%3Cg id='Valitsusportaal-2.0' stroke='none' stroke-width='1' fill='none' fill-rule='evenodd'%3E%3Cg id='Valitsusportaal_esileht-kalender-Copy' transform='translate(-699.000000, -369.000000)' fill='%23006eb5' fill-rule='nonzero'%3E%3Cg id='check' transform='translate(699.000000, 369.000000)'%3E%3Cpolygon id='tick' transform='translate(6.726522, 5.145431) rotate(-180.000000) translate(-6.726522, -5.145431) ' points='8.36624308 2.91060443 1.4603688 10.2908615 0 8.92436127 8.35072763 0 13.4530432 5.33759166 12.0073256 6.71958328'%3E%3C/polygon%3E%3C/g%3E%3C/g%3E%3C/g%3E%3C/svg%3E");
  background-position: 50% 50%;
}

.c-icon {
  display: inline-block;
  fill: currentColor;
  height: 1em;
  position: relative;
  top: -0.0625em;
  vertical-align: middle;
  text-align: center;
  width: 1em;
  margin-right: 0.2rem;

  &.c-icon-absolute-vp {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    left: 1rem;
  }
}

.font-size-sm-vp {
  font-size: $h5-font-size;
}

a.a-arrow-vp {
  padding-right: 1.5rem;
  position: relative;

  &::after {
    position: absolute;
    top: 1px;
    right: 15px;
    content: "";
    background-image: url("data:image/svg+xml,%3C%3Fxml version='1.0' encoding='UTF-8'%3F%3E%3Csvg width='16px' height='12px' viewBox='0 0 16 12' version='1.1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink'%3E%3C!-- Generator: Sketch 51.1 (57501) - http://www.bohemiancoding.com/sketch --%3E%3Ctitle%3Earrow-right%3C/title%3E%3Cdesc%3ECreated with Sketch.%3C/desc%3E%3Cdefs%3E%3C/defs%3E%3Cg id='Valitsusportaal-2.0' stroke='none' stroke-width='1' fill='none' fill-rule='evenodd'%3E%3Cg id='Valitsusportaal_esileht-kalender-Copy' transform='translate(-697.000000, -130.000000)' fill='%23006eb5' fill-rule='nonzero'%3E%3Cg id='arrow-right' transform='translate(697.000000, 130.000000)'%3E%3Cpolygon id='Triangle' points='8.29289322 1.70710678 9.70710678 0.292893219 15.4142136 6 9.70710678 11.7071068 8.29289322 10.2928932 12.5857864 6'%3E%3C/polygon%3E%3Cpolygon id='Line' points='0.46875 7 0.46875 5 13 5 13 7'%3E%3C/polygon%3E%3C/g%3E%3C/g%3E%3C/g%3E%3C/svg%3E");
    opacity: 0;
    width: 1rem;
    height: 1rem;
    background-size: contain;
    background-repeat: no-repeat;
    transition: all 0.4s;
  }

  &:hover,
  &:focus {
    text-decoration: none;
  }

  &:hover::after,
  &:focus::after {
    opacity: 1;
    right: 0;
    // transform: translate(0, -50%);
  }
}

.read-more-vp {
  padding-right: 1.5rem;
  position: relative;
  display: inline-block;
  width: auto;

  .c-icon {
    margin-right: 0;
    position: absolute;
    right: 0;
    top: 50%;
    margin-top: -1px;
    opacity: 0;
    width: 1rem;
    height: 1rem;
    min-width: 1rem;
    min-height: 1rem;
    transform: translate(-15px, -50%);
    transition: all 0.4s;
  }
}
