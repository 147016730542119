// Styles for documentation

// Set Contrast Threshold
$yiq-contrasted-threshold: 195 !default;

// Spacing Variables
// Change below variable if the height of the navbar changes
$topbar-base-height: 4.375rem;
// Change below variable to change the width of the sidenav
$sidebar-base-width: 14rem;
// Change below variable to change the width of the sidenav when collapsed
$sidebar-collapsed-width: 6.5rem;

// Adjust column spacing for symmetry
$spacer: 1rem;
$grid-gutter-width: $spacer * 1.5;

// Transitions
$transition-collapse: height 0.15s ease !default;

// Dropdowns
$dropdown-font-size: 0.85rem;
$dropdown-border-color: $border-color;

// Global styles for both custom sidebar components

.sidebar {
  @include mq(768) {
    min-width: 320px;
  }

  .nav-item {
    // Customize Dropdown Arrows for Navbar
    &.dropdown {
      .dropdown-toggle {
        &::after {
          width: 1rem;
          text-align: center;
          float: right;
          vertical-align: 0;
          border: 0;
          font-weight: 900;
          content: "\f105";
          font-family: "Font Awesome 5 Free";
        }
      }

      &.show {
        .dropdown-toggle::after {
          content: "\f107";
        }
      }
    }

    // Counter for nav links and nav link image sizing
    .nav-link {
      position: relative;

      .badge-counter {
        position: absolute;
        transform: scale(0.7);
        transform-origin: top right;
        right: 0.25rem;
        margin-top: -0.25rem;
      }

      .img-profile {
        height: 2rem;
        width: 2rem;
      }
    }
  }
}

button.nav-link {
  background-color: transparent;
  border: 0px;
}

//
// Docs color palette classes
//

@each $color, $value in $colors {
  .swatch-#{$color} {
    color: color-yiq($value);
    background-color: #{$value};
  }
}

@each $color, $value in $theme-colors {
  .swatch-#{$color} {
    color: color-yiq($value);
    background-color: #{$value};
  }
}

@each $color, $value in $grays {
  .swatch-#{$color} {
    color: color-yiq($value);
    background-color: #{$value};
  }
}

// Sidebar
.sidebar {
  width: $sidebar-collapsed-width;
  min-height: 100vh;

  .nav-item {
    position: relative;

    &:last-child {
      margin-bottom: 1rem;
    }

    .nav-link {
      text-align: center;
      padding: 0.75rem 1rem;
      width: 100%;

      span {
        font-size: 1rem;
        display: block;
      }
    }

    &.active {
      .nav-link {
        font-weight: 700;
      }
    }

    // Accordion
    .collapse {
      position: absolute;
      left: calc(#{$sidebar-collapsed-width} + #{$grid-gutter-width} / 2);
      z-index: 1;
      top: 2px;
      // Grow In Animation
      @extend .animated--grow-in !optional;

      .collapse-inner {
        border-radius: $border-radius-sm;
        box-shadow: $box-shadow;
      }
    }

    .collapsing {
      display: none;
      transition: none;
      background-color: rgba(255, 255, 255, 0.2);
      animation: none;
    }

    .collapse,
    .collapsing {
      .collapse-inner {
        padding: 0.5rem 0;
        min-width: 10rem;
        font-size: $dropdown-font-size;
        margin: 0 0 1rem 0;

        .collapse-header {
          margin: 0;
          white-space: nowrap;
          padding: 0.5rem 1.5rem;
          text-transform: uppercase;
          font-weight: 800;
          font-size: 1;
          color: $gray-500;
        }

        .collapse-item {
          padding: 0.5rem 1rem;
          margin: 0 0.5rem;
          display: block;
          color: $white;
          text-decoration: none;
          border-radius: $border-radius-sm;
        }
      }
    }
  }

  #sidebarToggle {
    width: 2.5rem;
    height: 2.5rem;
    text-align: center;
    margin-bottom: 1rem;
    cursor: pointer;

    &::after {
      font-weight: 900;
      content: "\f104";
      font-family: "Font Awesome 5 Free";
      margin-right: 0.1rem;
    }

    &:hover {
      text-decoration: none;
    }

    &:focus {
      outline: none;
    }
  }

  &.toggled {
    width: 0 !important;
    overflow: hidden;

    #sidebarToggle::after {
      content: "\f105";
      font-family: "Font Awesome 5 Free";
      margin-left: 0.25rem;
    }
  }

  .sidebar-brand {
    height: $topbar-base-height;
    text-decoration: none;
    font-size: 1rem;
    font-weight: 800;
    padding: 1.5rem 1rem;
    text-align: center;
    text-transform: uppercase;
    letter-spacing: 0.05rem;
    z-index: 1;

    .sidebar-brand-icon i {
      font-size: 2rem;
    }

    .sidebar-brand-text {
      display: none;
    }
  }

  hr.sidebar-divider {
    margin: 0 1rem 1rem;
  }

  .sidebar-heading {
    text-align: center;
    padding: 0 1rem;
    font-weight: 800;
    font-size: 1rem;
    @extend .text-uppercase !optional;
  }
}

@include media-breakpoint-up(md) {
  .sidebar {
    width: $sidebar-base-width !important;

    .nav-item {
      // Accordion
      .collapse {
        position: relative;
        left: 0;
        z-index: 1;
        top: 0;
        animation: none;
        background-color: rgba($white, 0.2);

        .collapse-inner {
          border-radius: 0;
          box-shadow: none;
        }
      }

      .collapsing {
        display: block;
        transition: $transition-collapse;
      }

      .collapse,
      .collapsing {
        margin: 0 1rem;
      }

      .nav-link {
        display: block;
        width: 100%;
        text-align: left;
        padding: 1rem;
        width: 100%;

        i {
          font-size: 0.85rem;
          margin-right: 0.25rem;
        }

        span {
          font-size: 1rem;
          display: inline;
        }

        // Accordion Arrow Icon
        &[data-toggle="collapse"] {
          &::after {
            width: 1rem;
            text-align: center;
            position: absolute;
            right: 1rem;
            top: 50%;
            transform: translateY(-50%);
            vertical-align: 0;
            border: 0;
            font-weight: 900;
            content: "\f107";
            font-family: "Font Awesome 5 Free";
          }

          &.collapsed::after {
            content: "\f105";
          }
        }
      }
    }

    .sidebar-brand {
      .sidebar-brand-icon i {
        font-size: 2rem;
      }

      .sidebar-brand-text {
        display: inline-block;
      }
    }

    .sidebar-heading {
      text-align: left;
    }

    &.toggled {
      overflow: visible;
      width: $sidebar-collapsed-width !important;

      .nav-item {
        // Accordion
        .collapse {
          position: absolute;
          left: calc(#{$sidebar-collapsed-width} + #{$grid-gutter-width} / 2);
          z-index: 1;
          top: 2px;
          // Grow In Animation for Toggled State
          animation-name: growIn;
          animation-duration: 200ms;
          animation-timing-function: transform cubic-bezier(0.18, 1.25, 0.4, 1),
            opacity cubic-bezier(0, 1, 0.4, 1);
          background-color: rgba($white, 0.2);

          .collapse-inner {
            box-shadow: $box-shadow;
            border-radius: $border-radius-sm;
          }
        }

        .collapsing {
          display: none;
          transition: none;
        }

        .collapse,
        .collapsing {
          margin: 0;
        }

        &:last-child {
          margin-bottom: 1rem;
        }

        .nav-link {
          text-align: center;
          padding: 0.75rem 1rem;
          width: 100%;

          span {
            font-size: 0.65rem;
            display: block;
          }

          i {
            margin-right: 0;
          }

          &[data-toggle="collapse"]::after {
            display: none;
          }
        }
      }

      .sidebar-brand {
        .sidebar-brand-icon i {
          font-size: 2rem;
        }

        .sidebar-brand-text {
          display: none;
        }
      }

      .sidebar-heading {
        text-align: center;
      }
    }
  }
}

// Sidebar Color Variants

// Sidebar Light
.sidebar-light {
  .sidebar-brand {
    color: $gray-700;
  }

  hr.sidebar-divider {
    border-top: 1px solid $gray-200;
  }

  .sidebar-heading {
    color: $gray-500;
  }

  .nav-item {
    .nav-link {
      color: $gray-600;

      i {
        color: $gray-400;
      }

      &:active,
      &:focus,
      &:hover {
        color: $gray-700;

        i {
          color: $gray-700;
        }
      }

      // Accordion
      &[data-toggle="collapse"]::after {
        color: $gray-500;
      }
    }

    &.active {
      .nav-link {
        color: $gray-700;

        i {
          color: $gray-700;
        }
      }
    }
  }

  // Color the sidebar toggler
  #sidebarToggle {
    background-color: $gray-200;

    &::after {
      color: $gray-500;
    }

    &:hover {
      background-color: $gray-300;
    }
  }
}

// Sidebar Dark
.sidebar-dark {
  background-color: $blue;

  .sidebar-brand {
    color: $white;
  }

  hr.sidebar-divider {
    border-top: 1px solid fade-out($white, 0.85);
  }

  .sidebar-heading {
    color: fade-out($white, 0.6);
  }

  .nav-item {
    .nav-link {
      color: fade-out($white, 0.2);

      i {
        color: fade-out($white, 0.7);
      }

      &:active,
      &:focus,
      &:hover {
        color: $white;

        i {
          color: $white;
        }
      }

      // Accordion
      &[data-toggle="collapse"]::after {
        color: fade-out($white, 0.5);
      }
    }

    &.active {
      .nav-link {
        color: $white;

        i {
          color: $white;
        }
      }
    }
  }

  // Color the sidebar toggler
  #sidebarToggle {
    background-color: fade-out($white, 0.8);

    &::after {
      color: fade-out($white, 0.5);
    }

    &:hover {
      background-color: fade-out($white, 0.75);
    }
  }

  &.toggled {
    #sidebarToggle::after {
      color: fade-out($white, 0.5);
    }
  }
}

.main-doc-vp {
  max-width: calc(100% - 6.5rem);
  width: 1440px;

  @include mq(768) {
    max-width: calc(100vw - 320px - 15px - 15px);
  }

  &.full-width-docs-vp {
    max-width: 100%;
    width: 100% !important;
    flex: unset !important;
    padding-left: 1rem !important;
    padding-right: 1rem !important;

    & > .container.container-body-vp {
      padding-left: 0;
      padding-right: 0;
    }
  }
}

h1 {
  word-break: break-word;
}

.sidebar-toggle-btn {
  position: fixed;
  bottom: 20px;
  left: 20px;
  z-index: 9999;

  &:hover {
    background-color: $primary;
    color: $light;
  }
}
