// Site footer

.site-footer-vp {
  padding: 3.75rem 0 0 0;
  @include mq(992) {
    padding: 3.75rem 0;
  }

  .row > div {
    margin-bottom: 1.5rem;
    @include mq(992) {
      margin-bottom: 2.5rem;
    }
  }
}

// Back top

.vp-back-top {
  position: fixed;
  bottom: 1rem;
  left: 1rem;
  z-index: $zindex-fixed;
  width: 2.8rem;
  height: 2.8rem;
  border-radius: 50%;
  border: $border-width * 2 solid transparent;
  transition: $btn-transition;
  display: none;
  box-shadow: $box-shadow-sm;
  background-color: $white;

  @include mq(992) {
    right: 1.5rem;
    bottom: 1.5rem;
    left: auto;
    border-color: $blue;
  }

  .c-icon {
    margin-right: 0;
    fill: $blue;
  }

  &:hover,
  &:focus {
    background: $blue;

    .c-icon {
      fill: $white;
    }
  }

  &.vp-show {
    display: flex;
  }
}

// Hero image

.vp-hero-image {
  height: 25rem;
  width: 100%;
  max-width: 1440px;
  background-size: cover;
  background-position: 50% 50%;
  background-repeat: no-repeat;
  margin: 0 auto;

  @include mq(767, max) {
    height: 180px;
  }

  @include mq(992, max) {
    height: 250px;
  }
}

// Pagination and slide controls

.vp-pagination,
.vp-slide-controls a, .vp-slide-controls .swiper-button,
.vp-calendar-controls a {
  cursor: pointer;
  width: 2.8rem;
  height: 2.8rem;
  border-radius: 50%;
  border: $border-width * 2 solid $blue;
  transition: $btn-transition;

  .c-icon {
    margin-right: 0;
    fill: $blue;
  }

  &:hover {
    background: $blue;

    .c-icon {
      fill: $white;
    }
  }
}

// Charts

.vp-chart {
  width: 100%;
  max-width: 100%;
  min-height: 200px;

  text {
    fill: $black !important;
    font-family: $font-family-sans-serif !important;
    font-size: $font-size-sm !important;
  }
}

// Overlays, gradients

.container-overlay-vp,
.container-gradient-vp {
  position: absolute;
}

.container-overlay-vp {
  left: 0;
  top: 0;
  bottom: 0;
  right: 0;
  background-color: rgba($blue, 0.85);
}

.container-gradient-vp {
  top: 0;
  bottom: 0;
  width: 411px;
  max-width: 50%;
}

.container-gradient-left-vp {
  left: 0;
  background-image: linear-gradient(-90deg, rgba(0, 57, 111, 0) 0%, $blue 100%);
}

.container-gradient-right-vp {
  right: 0;
  background-image: linear-gradient(90deg, rgba(0, 57, 111, 0) 0%, $blue 100%);
}

// Social media

.vp-social-box {
  display: none;
  padding: 1rem;

  @include mq(992) {
    display: block;
    padding: 22px;
  }

  @media (min-width: 768px) and (max-width: 1280px) {
    margin-bottom: -100px;
    margin-top: 2rem;
  }

  @include mq(1281) {
    position: absolute;
    top: -10rem;
    left: 45px;
    right: 45px;
    width: calc(100% - 45px - 45px);
  }
}

// Cookie consent

.cookie-consent-vp {
  display: none;
  position: fixed;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: $zindex-tooltip;
}

.header-bg-sm-vp {
  height: 18rem;
  background-size: cover;
  background-position: 50% 50%;
  overflow: hidden;
  position: relative;

  @include mq(1281) {
    height: 23.5rem;
  }

  iframe {
    min-height: 100%;
    width: 100%;
  }
}

// Load more

.load-all-options-vp svg,
.load-more-options-vp svg {
  padding: 2px;
  border: $border-width * 2 solid $indigo;
  border-radius: 50%;
  height: 20px;
  width: 20px;
  min-width: 20px;
  min-height: 20px;
  transition: $btn-transition;
  stroke: currentColor;
}

.load-all-options-vp:hover,
.load-more-options-vp:hover,
.load-all-options-vp:focus,
.load-more-options-vp:focus {
  text-decoration: none;
}

.load-all-options-vp:hover svg,
.load-more-options-vp:hover svg,
.load-all-options-vp:focus svg,
.load-more-options-vp:focus svg {
  background-color: $indigo;
  fill: $white;
}

.open-all-options-vp {
  display: flex;

  @include mq(991, max) {
    width: 100%;
  }

  &.hide {
    display: none;
  }
}

.close-all-options-vp {
  display: none;

  @include mq(991, max) {
    width: 100%;
  }

  &.show {
    display: flex;
  }

  svg {
    stroke: currentColor;
  }

  &:hover svg,
  &:focus svg {
    stroke: $white;
  }
}

.options-hidden-vp {
  display: none;
}

// Ajax loader

.ajax-loader-vp {
  width: 25px;
  height: 25px;
  stroke-dasharray: 134;
  stroke-dashoffset: -135;
  margin: 0 auto;
  position: relative;
  display: inline-block;
  animation: animateDash 2s infinite, animateTransform 2s infinite;
  animation-timing-function: linear;
}

@keyframes animateDash {
  0% {
    stroke-dashoffset: -135;
  }
  50% {
    stroke-dashoffset: 0;
  }
  100% {
    stroke-dashoffset: 135;
  }
}

@keyframes animateTransform {
  to {
    transform: rotate(360deg);
  }
}

.row-vs-vp .col-vs-vp {
  position: relative;

  &:before {
    position: absolute;
    height: 42px;
    width: 42px;
    top: 0;
    left: 50%;
    transform: translate(-50%, -50%);
    background-color: $blue;
    color: $white;
    content: "Vs";
    text-transform: uppercase;
    display: flex;
    border-radius: 50%;
    align-items: center;
    justify-content: center;
    font-size: 18px;
    font-weight: $font-weight-light;

    @include mq(1281) {
      top: 50%;
      left: 0;
    }
  }
}

.pre-footer-vp .alert {
  @include mq(1280, max) {
    padding-left: 0;
  }
}

.show-list-items {
  .c-icon {
    width: 9px;
  }
  span {
    &.hidden {
      display: none;
    }
  }
}

.show-list-items.hide {
  display: none;
}

.hide-list-items,
.hidden-items-vp {
  display: none;

  &.show {
    display: block;
  }
}

.search-result-profile-vp {
  border: $border-width solid $border-color;
  padding: 2rem;
  max-width: 390px;

  @include mq(992) {
    border-top: none;
  }
}

.search-options-container-vp {
  padding: 2rem 1rem;
  margin-left: -15px;
  margin-right: -15px;

  @include mq(992) {
    width: 100%;
    margin-left: auto;
    margin-right: auto;
    padding: 2rem 2rem 1rem 2rem;
  }
}

.event-search-vp {
  @include mq(992, max) {
    flex-wrap: wrap;
  }
}

.event-search-vp .form-group {
  margin-bottom: 0;

  @include mq(992) {
    padding-right: 1rem;
  }

  &.has-search {
    width: 27rem;
  }

  &:nth-last-of-type {
    padding-right: 0;
  }

  @include mq(992, max) {
    width: calc(50% - 1rem);
    margin-bottom: 1rem;

    &.has-search {
      max-width: 50%;
      width: calc(50% - 1rem);
    }
  }

  @include mq(992, max) {
    width: 100%;

    &.has-search {
      max-width: 100%;
      width: 100%;
    }

    &.has-search input {
      width: 100%;
    }
  }
}
