.collapse-vp {
  padding: 0;
  border: $border-width solid $border-color;
  border-bottom: none;
}

.collapse-vp .card-body {
  border-bottom: $border-width solid $border-color;
  padding: 1.25rem;
  border-left: $border-width * 4 solid $indigo;

  @include mq(992) {
    padding: 1.25rem 2rem;
  }
}

.collapse-header-vp .btn-link,
.vertical-tab-container-vp .card-header a {
  text-align: left;
  text-transform: uppercase;
  padding: 1.25rem 3rem 1.25rem 1.25rem;
  border-radius: 0;
  width: 100%;
  display: flex;
  cursor: pointer;
  background: $gray-100;
  font-size: $font-size-sm;
  border-left: $border-width * 4 solid transparent;
  border-top: $border-width solid $border-color;
  border-bottom: $border-width solid $border-color;
  border-color: transparent transparent #eeeeee transparent;
  position: relative;

  @include mq(992) {
    padding: 1.25rem 2rem;
  }

  &[aria-expanded="true"] {
    border-left: $border-width * 4 solid $indigo;
    color: $indigo;
    background: $white;
    font-weight: $font-weight-bold;
    border-bottom: none;
  }

  &[aria-expanded="true"]::after {
    transform: rotateZ(225deg);
    vertical-align: baseline;
    border-color: $indigo;
  }

  &::after {
    display: inline-block;
    width: 0.5em;
    height: 0.5em;
    margin: -0.3em 0 0 0.4em;
    vertical-align: middle;
    content: "";
    border: 0.3em solid;
    border-width: 0 0.15em 0.15em 0;
    border-color: $blue;
    position: absolute;
    right: 1.25rem;
    top: 50%;
    transform: translateY(-50%) rotateZ(45deg) rotate(0deg);

    @include mq(768) {
      right: 2rem;
    }
  }
}

.collapse-header-vp .btn-link {
  @include mq(768) {
    padding-right: 4rem;
  }
}
