.topbar {
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: center;
  position: relative;
  gap: 0.5rem;

  @include mq(850) {
    padding-bottom: 1rem;
  }
}

html {
  scroll-behavior: smooth !important;

}