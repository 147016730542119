.list-group {
  position: relative;
  min-width: 180px;

  &:before {
    width: 1px;
    background: $gray-500;
    content: "";
    position: absolute;
    left: 0;
    top: calc(#{$list-group-item-padding-y} * 2);
    bottom: calc(#{$list-group-item-padding-y} * 2);
  }

  .list-group-item {
    font-weight: $font-weight-bold;
  }

  &.list-group-no-border-vp {
    &:before {
      display: none;
    }

    .list-group-item {
      padding-left: 0;
    }
  }

  &.list-group-l-padding-vp .list-group-item {
    padding-left: 1.25rem;
  }
}

.list-group.list-social-vp {
  flex-direction: row;
  align-items: center;

  &:before {
    display: none;
  }

  .list-group-item {
    margin-right: 2.5rem;
    margin-bottom: 1rem;
    padding: 0;
    transition: $btn-transition;
    transform: translateY(0);

    a {
      g {
        fill: #006eb5;
      }
      &:hover {
        g {
          fill: white;
        }
      }
    }

    &:last-of-type {
      margin-right: 0;
    }

    &:hover {
      transform: translateY(-5px);
    }
  }

  .list-group-item a {
    border: $border-width * 2 solid rgba($indigo, 0.3);
    height: 2.8rem;
    position: relative;
    border-radius: 50%;
    color: $indigo;
    transition: $btn-transition;
  }

  .c-icon {
    height: 1.31rem;
    margin-right: 0;
  }

  .list-group-item a:hover,
  .list-group-item a:focus {
    border: $border-width * 2 solid rgba($blue, 0.3);
    background-color: $blue;
    color: $white;
  }
}

.list-group.list-social-simple-vp {
  .list-group-item a {
    border: none;
    height: auto;
  }

  .list-group-item a:hover,
  .list-group-item a:focus {
    border: none;
    background-color: transparent;
    color: $blue;
  }

  .c-icon {
    height: 1rem;
  }
}

.list-social-vertical-vp {
  left: -3.4rem;
  position: absolute;
  display: none;

  @include mq(1281) {
    display: flex;
  }

  @include mq(992) {
    left: -5.4rem;
  }

  .list-social-item-vp {
    list-style: none;
  }

  .list-social-item-vp {
    color: $indigo;
  }

  .list-social-item-vp a {
    padding: 1.38rem;
    display: block;
    color: $indigo;
  }

  .list-social-item-vp svg {
    transition: $btn-transition;
  }

  .list-social-item-vp a:hover svg,
  .list-social-item-vp a:focus svg {
    fill: $blue;
  }

  .list-social-item-rotated-vp {
    text-transform: uppercase;
    font-size: $h5-font-size;
    position: relative;
    transform: rotate(-90deg);
    margin: 1rem 0;
  }
}

.list-social-vertical-fixed-vp .list-social-vertical-vp {
  position: fixed;
  left: 0;
  bottom: 0.5rem;
  padding-left: 0;
  margin-bottom: 0;
  transition: $btn-transition;

  li {
    color: $indigo;
  }

  a {
    padding: 0.6rem 0.7rem;
    color: $indigo;

    @include mq(1440) {
      padding: 0.6rem 1.38rem;
    }
  }

  a:hover svg,
  a:focus svg {
    fill: $blue;
  }
}

.list-with-image-vp .list-image-vp,
.list-with-image-vp .list-image-lg-vp {
  width: 3.75rem;
  height: 3.75rem;
  min-width: 3.75rem;
  min-height: 3.75rem;
  border-radius: 50%;
  margin-right: 1rem;
  background-size: cover;
  background-position: 50% 50%;
  background-repeat: no-repeat;
}

.list-with-image-vp .list-image-lg-vp {
  width: 100px;
  height: 100px;
  min-width: 100px;
  min-height: 100px;

  @include mq(768) {
    margin-right: 2rem;
    width: 150px;
    height: 150px;
    min-width: 150px;
    min-height: 150px;
    max-width: 150px;
  }
}

.list-group-absolute-vp {
  @include mq(768) {
    position: absolute;
    // bottom: 20%;
    bottom: 0;
  }
}

.list-group-dark-vp {
  &:before {
    background: rgba($white, 0.2);
  }

  .list-group-item {
    color: $white;
  }

  .list-group-item.active {
    font-weight: $font-weight-bold;
  }
}

.list-group-item:hover,
.list-group-item:focus {
  text-decoration: underline;
}

.site-footer-vp .list-group.list-social-vp {
  @include mq(768) {
    margin-bottom: 2rem;
  }

  @include mq(768) {
    margin-bottom: 2rem;
  }
}

.list-group-vp {
  @include mq(767, max) {
    margin-bottom: 2rem;
  }

  a {
    transform: translateX(0px);
    transition: transform 0.35s;
  }

  p {
    padding: $list-group-item-padding-y $list-group-item-padding-x;
  }

  a:hover,
  a:focus {
    @include mq(768) {
      transform: translateX(5px);
    }
  }
}
.list-absolute-vp {
  position: relative;

  .c-icon {
    position: absolute;
    left: 0;
    top: 3px;
  }

  a {
    padding-left: 1.6rem;
    display: inline-block;

    @include mq(767, max) {
      word-break: break-word;
    }
  }

  word-break: break-word;
}

.dark-menu-vp {
  &.dark-menu-lg-vp {
    @include mq(768) {
      margin-top: -3rem;
    }
  }

  .list-group-vp {
    @include mq(767, max) {
      margin-bottom: 0;
    }

    &.hidden-items-vp {
      display: none;

      @include mq(768) {
        display: flex !important;
      }
    }
  }

  .list-container-vp {
    .c-icon {
      width: 9px;
    }
    span {
      &.hidden {
        display: none;
      }
    }
  }

  .show-list-items {
    width: 50px;
    border-left: 1px solid rgba(255, 255, 255, 0.2);

    @include mq(768) {
      pointer-events: none;
      border: none;
    }
  }

  svg {
    @include mq(768) {
      display: none;
    }
  }

  .list-container-vp {
    @include mq(768) {
      margin-top: -2rem;
    }
  }

  .list-container-vp .hidden-items-vp {
    padding: 0 2rem 2rem;
    position: relative;
    margin-top: 1rem;

    &.list-group:before {
      bottom: 2.5rem;
      left: 22.5px;

      @include mq(768) {
        left: 2rem;
      }
    }
  }

  .dark-menu-header-vp {
    @include mq(767, max) {
      border-bottom: 1px solid rgba(255, 255, 255, 0.2);
    }
  }

  .dark-menu-header-vp h5,
  .dark-menu-header-vp a {
    padding: 11px 22.5px;

    @include mq(768) {
      padding: 2rem 2rem 0;
    }
  }

  &.no-margin-vp .list-container-vp {
    @include mq(768) {
      margin-top: 0;
    }
  }
}

.menu-col-vp .list-group-vp,
.menu-col-vp p {
  @include mq(991, max) {
    margin-bottom: 0;
  }
}

.side-menu-vp {
  @include mq(768) {
    &.side-menu--grow-up {
      position: absolute;
      left: 22.5px;
      right: 22.5px;
      bottom: 0;
    }

    &.side-menu--grow-down {
      margin-top: -200px;
    }
  }
}
