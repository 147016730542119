// Overrides for default Bootstrap variables.
@import "vp/variables";

// Mixins
@import "vp/mixins";

// Utilities

@import "vp/utilities";

// General

@import "vp/general";

// High contrast

@import "vp/high-contrast";

// Print

@import "vp/print";

// Flatpickr theme

@import "../../node_modules/flatpickr/dist/themes/material_blue.css";

// AOS
// Imported from vendors due to version bug that does not allow custom values for animations


// Core Bootstrap parts
// Only used parts are imported to the project.
@import "bootstrap/bootstrap";

// Modified/extended Bootstrap components.
// Same filename as Bootstrap for easy reference.
@import "vp/bootstrap-extend/buttons";
@import "vp/bootstrap-extend/tabs";
@import "vp/bootstrap-extend/nav";
@import "vp/bootstrap-extend/dropdown";
@import "vp/bootstrap-extend/type";
@import "vp/bootstrap-extend/list-group";
@import "vp/bootstrap-extend/forms";
@import "vp/bootstrap-extend/grid";
@import "vp/bootstrap-extend/collapse";
@import "vp/bootstrap-extend/modal";
@import "vp/bootstrap-extend/custom-forms";

// Helpers for documentation.
@import "doc";

// Custom modifiers for core Bootstrap components.
// Note the `*-vp` suffix on class names.

// Custom components.
// Note the `vp-*` prefix with naming.
@import "components/customAccordion";
@import "components/customSwiper";
@import "components/haiBlock";
@import "components/hero";
@import "components/layout";
@import "components/buttons";



