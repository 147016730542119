.container.container-body-vp {
  background-size: cover;
  background-repeat: no-repeat;
  background-position: 50% 50%;
  max-width: 100%;

  @include mq(992) {
    width: calc(73.8rem + 115px);
  }

  @include mq(1281) {
    padding: 0 35px;
  }

  @include mq(1440) {
    //max-width: 1440px;
    padding: 0 60px;
  }

  @include mq(767, max) {
    padding: 0 1rem;
  }
}

.container.container-body-sm-vp {
  max-width: 100%;

  @include mq(992) {
    width: calc(42rem + 120px);
  }
}

.container.container-body-md-vp {
  max-width: 100%;

  @include mq(992) {
    width: calc(73.8rem + 115px);
  }
}

.container.container-body-lg-vp {
  width: 1440px;
  max-width: 100%;
}

.container-fluid {
  overflow: hidden;
}
