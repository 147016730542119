.hai {
  padding-bottom: 2rem;
  .top {
    margin-bottom: 4rem;

    .upper {
      color: #003087;
      margin-bottom: 4rem;

      .links {
        color: #006eb5;
      }
    }

    .breakline {
      display: block;
      content: "";
      height: 1.5rem;
    }
  }
}
