.high-contrast *:not(.navbar-brand *) {
  background-color: #000 !important;
  color: #ff0 !important;
  :not(li) {
    border-color: #ff0 !important;

  }
  stroke: black !important;
  fill: #ff0 !important;
  text-decoration-color: #ff0 !important;
}

.high-contrast .c-icon,
.high-contrast .c-icon:hover {
  fill: #ff0 !important;
}

.high-contrast .form-group input:not(.input-no-shadow-vp),
.high-contrast .form-group textarea {
  box-shadow: none !important;
}

.high-contrast .bg-secondary,
.high-contrast .bg-light,
.high-contrast .bg-primary,
.high-contrast .bg-white {
  background-color: #000 !important;
}

.high-contrast .border {
  border-color: #ff0 !important;
}

.high-contrast .text-dark,
.high-contrast .text-light,
.high-contrast .text-white,
.high-contrast .text-primary,
.high-contrast .text-secondary,
.high-contrast a {
  color: #ff0 !important;
}

.high-contrast a {
  border-color: #ff0 !important;

  &:hover,
  &:focus {
    border-color: #ff0 !important;
    color: #ff0 !important;
  }
}

.high-contrast :after,
.high-contrast :before {
  color: #ff0 !important;
  border-color: #ff0 !important;
  fill: #ff0 !important;
}

.high-contrast .radio {
  background-color: #ff0 !important;
}

.high-contrast .checkbox {
  background-color: #ff0 !important;
}

.high-contrast .custom-control-label::before,
.high-contrast .custom-control-input:checked ~ .custom-control-label::before {
  border: $border-width solid #ff0;
  background-color: transparent;
}

.high-contrast .full-title.highlight-2,
.high-contrast .full-title.highlight-4,
.high-contrast .title-line.highlight-2,
.high-contrast .title-line.highlight-4 {
  background-color: #000 !important;
}

.high-contrast .row [class*="col-"] .news-thumb .image:after {
  display: none !important;
}

.high-contrast nav.mobile .search button,
.high-contrast nav.mobile .search input[type="text"] {
  border: 1px solid #ff0;
}

.high-contrast .form-item [class*="before-"] {
  background-color: 0 0 !important;
}

.high-contrast input::-webkit-input-placeholder,
.high-contrast textarea::-webkit-input-placeholder {
  color: #ff0 !important;
}

.high-contrast input:-moz-placeholder,
.high-contrast textarea:-moz-placeholder {
  color: #ff0 !important;
  opacity: 1;
}

.high-contrast input::-moz-placeholder,
.high-contrast textarea::-moz-placeholder {
  color: #ff0 !important;
  opacity: 1;
}

.high-contrast input:-ms-input-placeholder,
.high-contrast textarea:-ms-input-placeholder {
  color: #ff0 !important;
}

.high-contrast input::-ms-input-placeholder,
.high-contrast textarea::-ms-input-placeholder {
  color: #ff0 !important;
}

.high-contrast .form-item,
.high-contrast p {
  z-index: 20;
  position: relative;
}

.high-contrast .accessibility + .wrapper-main header.main:after {
  border-top: 20px solid #ff0;
}

.high-contrast .accessibility {
  border-bottom: 2px solid transparent !important;
  transition: all 0.5s;
}

.high-contrast .accessibility.active {
  border-color: #ff0 !important;
}
