.modal.right .modal-dialog {
  width: 350px;
  max-width: 100%;
  margin-top: 0;
  margin-bottom: 0;

  @include mq(768) {
    top: 4rem;
  }

  @include mq(767, max) {
    width: 100%;
  }
}

.modal.right.modal-lg-vp .modal-dialog {
  width: 570px;
  max-width: 100%;
  top: 0;

  @include mq(767, max) {
    width: 100%;
  }
}

.modal-body {
  padding: 1rem;

  @include mq(768) {
    padding: 0;
  }
}

.modal-open .modal.right {
  padding-right: 0 !important;
  z-index: $zindex-services-modal;
}

.modal.right .modal-content {
  overflow-y: auto;
  padding: 0;

  @include mq(768) {
    padding: 2rem;
  }
}

.modal.right.fade .modal-dialog {
  right: -350px;
  margin-right: 0;
  transition: opacity 0.3s linear, right 0.3s ease-out;

  @include mq(767, max) {
    margin: 0 auto;
  }
}

.modal.right.modal-lg-vp.fade .modal-dialog {
  right: -570px;
}

.modal.right.fade.show .modal-dialog {
  right: 0;
}

.modal-header .close {
  position: relative;
  opacity: 1;

  &:before,
  &:after {
    position: absolute;
    left: 50%;
    content: "";
    height: 25px;
    width: 2px;
    background-color: #b1b3b3;
  }
  &:before {
    transform: translateY(-50%) translateX(-50%) rotate(45deg);
  }
  &:after {
    transform: translateY(-50%) translateX(-50%) rotate(-45deg);
  }

  @include mq(767, max) {
    margin: 0rem 0rem -1rem auto;
  }
}

.modal-dialog .c-icon {
  top: auto;
}

.vp-modal-close-alt {
  width: 45px;
  border-radius: 10px 0 0 10px;
  display: none;

  @include mq(768) {
    display: block;
    position: relative;
  }
}

.modal.show .vp-modal-close-alt {
  display: flex;
  flex-direction: column;
  align-items: center;
} 

.vp-modal-close-alt .btn {
  @include mq(768) {
    padding: 15px 10px;
    writing-mode: tb-rl;
    transform: rotate(180deg);
    pointer-events: auto;
    position: relative;
    display: flex;
    flex-direction: row;
    align-items: center;
    line-height: 1;

    .c-icon {
      margin: 0;
      transform: rotate(90deg);
      position: relative;
    }
  }
}

.modal-gallery-vp {
  .modal-body {
    @include mq(767, max) {
      padding: 0;
    }
  }
  .modal-dialog {
    background-image: linear-gradient(180deg, $white 0%, rgba($white, 0) 100%);
    min-width: 100vw;
    height: 100vh;
    max-width: none;
    margin: 0;
  }

  .modal-header .close {
    margin: 0 0 0 auto;
  }
}

.white-backdrop-vp .modal-backdrop {
  background-color: $white !important;
}

.vp-video-card:before {
  display: none;
}
