
  .round-icon {
    width: 2.8rem;
    height: 2.8rem;
    border-radius: 50%;
    display: inline-flex;
    align-items: center;
    justify-content: center;

    &-props {
      height: 20px;
      width: 20px;
    }
  }
