.vp-text-underline {
  text-decoration: underline;
}

.row-border-y-vp {
  border-top: $border-width solid $gray-500;
  border-bottom: $border-width solid $gray-500;
}

.row-border-vp {
  border: $border-width solid $gray-500;
}

.row-border-light-vp {
  border: $border-width solid $gray-300;
}

.row.row-large-gutters-vp {
  margin-right: -22.5px;
  margin-left: -22.5px;

  > [class*="col-"] {
    padding-right: 22.5px;
    padding-left: 22.5px;
  }
}

.row.row-xl-gutters-vp {
  margin-right: -44px;
  margin-left: -44px;

  > [class*="col-"] {
    padding-right: 44px;
    padding-left: 44px;
  }
}

.p-y-sm-vp {
  padding-left: 0.5rem;
  padding-right: 0.5rem;
}

.w-150-vp {
  width: 150px;
  max-width: 100%;

  &.col-sm {
    width: 150px;
  }
}

.w-325-vp {
  width: 325px;
  max-width: 100% !important;
}

.w-750-vp {
  width: 750px;
  max-width: 100% !important;
}

.mh-25-vp {
  min-height: 25rem;
}

.bg-black {
  background-color: $black;
}

.bg-red {
  background-color: $red;
}

.text-red {
  color: $red;
}

.svg-large-vp,
.svg-large-vp.c-icon {
  height: 0.6875rem;
  width: 1.25rem;
}

.font-size-sm {
  font-size: $font-size-sm;
}

.font-size-base {
  font-size: $font-size-base;
}

.font-size-lg {
  font-size: $font-size-lg;
}

.inline-flex-items-vp {
  display: block;

  @include mq(992) {
    display: inline-flex;
  }
}

.d-none-vp {
  display: none !important;
}

.d-xl-flex-vp,
.d-none-vp.d-xl-flex-vp {
  @include mq(1281) {
    display: flex !important;
  }
}

.d-xl-none-vp {
  @include mq(1281) {
    display: none !important;
  }
}

.line-height-md-vp {
  line-height: 24px;
}

.col-r-bordered-vp {
  position: relative;
  padding-left: 0;

  @include mq(992) {
    padding-left: 2rem;
  }

  &:before {
    content: "";
    height: 100%;
    width: $border-width;
    background-color: #d8d8d8;
    position: absolute;
    left: 0;
    top: 0;
    bottom: 0;
    display: none;

    @include mq(992) {
      display: block;
    }
  }
}

.inner-padding-vp {
  padding: 2rem 1rem;

  @include mq(1281) {
    padding: 3rem;
  }
}

.flex-column-mobile-vp {
  @include mq(991, max) {
    flex-direction: column;
  }
}

.align-items-end-desktop-vp {
  @include mq(992) {
    align-items: flex-end;
  }
}

.order-1-mobile-vp {
  @include mq(991, max) {
    display: flex;
    order: 1;
  }
}

.border-light-vp {
  border-color: $gray-300 !important;
}

.border-secondary-vp {
  border-color: $blue !important;
}

.xs-w-100-vp {
  @include mq(767, max) {
    width: 100%;
    display: block;
  }
}

.mobile-no-gutters-vp {
  @include mq(767, max) {
    padding-left: 0 !important;
    padding-right: 0 !important;
  }
}

.d-flex.d-xl-none-vp {
  @include mq(1051) {
    display: none !important;
  }
}

.w-fit {
  width: fit-content;
}

.list-style-none {
  list-style: none;
}

.mr-1 {
  margin-right: 1rem;
}

.mb-1 {
  margin-bottom: 1rem;
}