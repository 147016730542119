.hero {
  display: flex;
  flex-direction: column;

  @include mq(850) {
    flex-direction: row;
    height: 360px;
  }

  .image {
    width: 100%;
    overflow: hidden;
    display: flex;
    justify-content: flex-end;
    height: 240px;

    @include mq(850) {
      width: calc(100% / 3 * 2);
      height: 360px;
    }
  }
  .nav{
    width: 100%;
    border-top: 1px solid #eeeeee;
    height: 300px;
    @include mq(850) {
      width: calc(100% / 3);
      height: 360px;
    }

    .item {
      display: flex;
      align-items: center;
      height: 25%;
      text-transform: uppercase;
      font-size: $font-size-sm;
      padding: 0 1.875rem;
      border-left: $border-width * 4 solid transparent;
      border-bottom: $border-width solid rgba(255, 255, 255, 0.3);
    }
  }
  .tabs {
    .guide-head {
      display: flex;
      gap: 2rem;
      background: #003087;
      color: white;

      &:hover {
        background: #006eb5;
      }

      .icon {
        width: 2.8rem;
        height: 2.8rem;
        border-radius: 50%;
        border: 2px solid white;
        display: inline-flex;
        align-items: center;
        justify-content: center;
        color: white;

        &-props {
          height: 20px;
          width: 20px;
          fill: white;
          color: white;
        }
      }
    }
  }
}

.imageButton {
  position: relative;
  overflow: hidden;
  height: 240px;
  @include mq(768) {
    height: auto;
  }
  .button {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
}
