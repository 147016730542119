.form-group {
  position: relative;
}

.form-group input,
.form-group textarea,
.form-group select {
  display: inline-block;
  width: 100%;
  max-width: 100%;
  min-width: 229px;

  &:focus {
    border: $border-width solid $indigo;
  }
}

.form-group input:not(.input-no-shadow-vp),
.form-group textarea {
  box-shadow: 0 0 0 30px white inset !important;
}

.form-group textarea.textarea-sm-vp {
  min-height: 5rem;
}

.form-group.vp-has-error input,
.form-group.vp-has-error textarea,
.form-group.vp-has-error select {
  border: $border-width solid $red;
}

.form-group.vp-has-error .vp-validation-message {
  color: $red;
  margin-top: 11px;

  span {
    padding-right: 8px;
  }
}

.form-group.vp-has-success {
  .c-icon.absolute-right-vp {
    fill: $green;
    margin-right: 0;
    left: auto;
    right: 1rem;
  }
}

.form-group textarea {
  min-height: 9rem;
}

textarea + label,
input[type="text"] + label,
input[type="email"] + label,
input[type="tel"] + label,
input[type="number"] + label,
input[type="text"] + label,
input[type="date"] + label,
input[type="password"] + label,
select + label {
  position: absolute;
  left: 15px;
  top: -7px;
  background: $white;
  padding: 0px 5px;
  transform: translateY(19px);
  transition: 0.4s, font 0.4s;

  &.form-required::after {
    content: "*";
    position: absolute;
    right: 0;
    transform: translateX(4px);
    color: #BA432A
  }
}

textarea.vp-input-focus,
input.vp-input-focus:not([type=checkbox]):not([type=radio]),
select.vp-input-focus {
  & + label {
    transform: translateY(0);
    font-size: $font-size-sm;
    color: $blue;

    &.form-required::after {
      transform: translateX(0);
    }
  }
}

textarea:focus + label,
input:focus + label,
select:focus + label {
  color: $indigo !important;
}

.form-group input:not(.placeholder-input-vp),
.form-group textarea {
  &:focus,
  &.vp-input-focus {
    padding-top: 0.875rem;
  }
}

select.select-has-label-vp {
  padding-top: 0.875rem;
  padding-right: 2.75rem;
}

select + label,
input[type="date"] + label {
  transform: translateY(0);
  font-size: $font-size-sm;
}

select option[default] {
  display: none;
}

.form-group .c-icon.c-icon-absolute-vp {
  height: 1.187rem;
  width: 1.125rem;
  fill: $blue;
}

.form-group .c-icon.c-icon-absolute-vp.absolute-right-vp {
  height: 0.75rem;
  width: 1rem;
}

.form-group .absolute-submit-vp {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  left: auto;
  right: 0;
  appearance: none;
  padding: 0.6rem 1rem;
  box-shadow: none;

  &:focus,
  &.active {
    .c-icon {
      fill: $indigo;
    }
  }

  .c-icon {
    position: relative;
    top: auto;
    transform: none;
    margin-right: 0;
    right: 0;
  }
}

.form-group:not(.search-lg-btn-vp) .absolute-submit-vp {
  border: none !important;
}

.form-group.has-search {
  @include mq(768) {
    max-width: 50%;
  }
}

.form-group.has-arrow-vp {
  &.arrow-focus-vp .btn .c-icon {
    fill: $indigo;
  }
}

.custom-control-label::before {
  box-shadow: none !important;
}

.form-group.has-search input {
  max-width: 100%;
  @include mq(768) {
    width: 30rem;
  }
}

.form-group.form-group-sm-vp {
  width: 20.5rem;
  max-width: 100%;
}

.form-group .form-control-lg-p-vp {
  padding-left: $input-btn-padding-x;
  padding-right: $input-btn-padding-x;
  width: 100%;
}

.custom-radio {
  padding-right: 0.5rem;
}

.form-container-vp {
  padding: 1rem;

  @include mq(1281) {
    padding: 3rem;
  }
}

.form-group input:focus,
.form-group textarea:focus,
.form-group select:focus {
  box-shadow: none;
  border-color: $indigo;

  .custom-control-label::before {
    border-color: $indigo;
  }
}

input.flatpickr-calendar-range-vp {
  min-width: 260px;
}

.flatpickr-calendar-range-vp .cur-month {
  font-weight: normal;
  margin-right: 10px;
}

.form-group select,
.flatpickr-calendar-vp,
.flatpickr-calendar-range-vp {
  position: relative;
  width: 100%;
  max-width: 100%;
  display: inline-block;
  appearance: none;
  -webkit-appearance: none;
  background: transparent;

  &::-ms-expand {
    display: none;
  }

  background-image: url("data:image/svg+xml,%3C%3Fxml version='1.0' encoding='UTF-8'%3F%3E%3Csvg width='11px' height='7px' viewBox='0 0 11 7' version='1.1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink'%3E%3C!-- Generator: Sketch 51.1 (57501) - http://www.bohemiancoding.com/sketch --%3E%3Ctitle%3Earrow-small-down%3C/title%3E%3Cdesc%3ECreated with Sketch.%3C/desc%3E%3Cdefs%3E%3C/defs%3E%3Cg id='Valitsusportaal-2.0' stroke='none' stroke-width='1' fill='none' fill-rule='evenodd'%3E%3Cg id='Valitsusportaal_esileht-kalender-Copy' transform='translate(-487.000000, -133.000000)' fill='%23003087' fill-rule='nonzero'%3E%3Cg id='arrow-small-down' transform='translate(487.000000, 133.000000)'%3E%3Cpolygon id='Triangle' transform='translate(5.603553, 3.500000) rotate(-270.000000) translate(-5.603553, -3.500000) ' points='6.08578644 3.5 2.29289322 -0.292893219 3.70710678 -1.70710678 8.91421356 3.5 3.70710678 8.70710678 2.29289322 7.29289322'%3E%3C/polygon%3E%3C/g%3E%3C/g%3E%3C/g%3E%3C/svg%3E");
  background-repeat: no-repeat;
  background-position: calc(100% - 1rem) center;
  background-size: 11px;

  &:focus {
    background-image: url("data:image/svg+xml,%3C%3Fxml version='1.0' encoding='UTF-8'%3F%3E%3Csvg width='11px' height='7px' viewBox='0 0 11 7' version='1.1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink'%3E%3C!-- Generator: Sketch 51.1 (57501) - http://www.bohemiancoding.com/sketch --%3E%3Ctitle%3Earrow-small-up%3C/title%3E%3Cdesc%3ECreated with Sketch.%3C/desc%3E%3Cdefs%3E%3C/defs%3E%3Cg id='Valitsusportaal-2.0' stroke='none' stroke-width='1' fill='%23003087' fill-rule='evenodd'%3E%3Cg id='Valitsusportaal_esileht-kalender-Copy' transform='translate(-553.000000, -133.000000)' fill='' fill-rule='nonzero'%3E%3Cg id='arrow-small-up' transform='translate(558.500000, 136.500000) scale(1, -1) translate(-558.500000, -136.500000) translate(553.000000, 133.000000)'%3E%3Cpolygon id='Triangle' transform='translate(5.603553, 3.500000) rotate(-270.000000) translate(-5.603553, -3.500000) ' points='6.08578644 3.5 2.29289322 -0.292893219 3.70710678 -1.70710678 8.91421356 3.5 3.70710678 8.70710678 2.29289322 7.29289322'%3E%3C/polygon%3E%3C/g%3E%3C/g%3E%3C/g%3E%3C/svg%3E");
  }
}

.form-group select.has-value-vp {
  border-color: $gray-500;
  background-image: url("data:image/svg+xml,%3C%3Fxml version='1.0' encoding='UTF-8'%3F%3E%3Csvg width='11px' height='7px' viewBox='0 0 11 7' version='1.1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink'%3E%3C!-- Generator: Sketch 51.1 (57501) - http://www.bohemiancoding.com/sketch --%3E%3Ctitle%3Earrow-small-down%3C/title%3E%3Cdesc%3ECreated with Sketch.%3C/desc%3E%3Cdefs%3E%3C/defs%3E%3Cg id='Valitsusportaal-2.0' stroke='none' stroke-width='1' fill='none' fill-rule='evenodd'%3E%3Cg id='Valitsusportaal_esileht-kalender-Copy' transform='translate(-487.000000, -133.000000)' fill='%23003087' fill-rule='nonzero'%3E%3Cg id='arrow-small-down' transform='translate(487.000000, 133.000000)'%3E%3Cpolygon id='Triangle' transform='translate(5.603553, 3.500000) rotate(-270.000000) translate(-5.603553, -3.500000) ' points='6.08578644 3.5 2.29289322 -0.292893219 3.70710678 -1.70710678 8.91421356 3.5 3.70710678 8.70710678 2.29289322 7.29289322'%3E%3C/polygon%3E%3C/g%3E%3C/g%3E%3C/g%3E%3C/svg%3E");

  & + label {
    color: $gray-600;
  }
}

.d-inline-flex select {
  margin-right: 1rem;
}

input.input-sm-vp {
  min-width: 170px;
}

.inline-flex-items-vp input.input-sm-vp {
  @include mq(992) {
    min-width: 50%;
  }
}

.add-file-vp {
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;

  @include mq(992) {
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
  }

  .btn {
    margin-top: 1rem;

    @include mq(992) {
      margin-top: 0;
    }
  }

  .c-icon {
    width: 20px;
    height: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    border: $border-width * 2 solid $indigo;
    border-radius: 50%;
    padding: 3px;
    display: inline-block;
    transition: $btn-transition;
  }

  .inputfile-vp + label:hover,
  .inputfile-vp + label:focus {
    .c-icon {
      fill: $white;
      background-color: $indigo;
      border-color: $indigo;
    }
  }
}

.js-full-search-vp {
  display: none;
  position: absolute;
  background: $white;
  left: 0;
  right: 0;
  top: 50%;
  transform: translateY(-50%);
}

.inputfile-vp {
  width: 0.1px;
  height: 0.1px;
  opacity: 0;
  overflow: hidden;
  position: absolute;
  z-index: -1;

  & + label {
    color: white;
    background-color: $white;
    display: inline-block;
    cursor: pointer;
    margin-bottom: 0;
    padding-right: 0.5rem;
  }

  & + label * {
    pointer-events: none;
  }

  &:focus + label,
  &.has-focus + label {
    box-shadow: 0 0 0 $input-btn-focus-width $input-btn-focus-color;
  }
}

.form-control:disabled,
.form-control[readonly] {
  background-color: $white;
}

.search-mobile-vp .has-search {
  margin-bottom: 0;
}
