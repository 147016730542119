.nav-tabs {
  .nav-item {
    transition: 0.4s;
  }

  .nav-link {
    font-weight: $font-weight-normal;
  }

  .nav-item.show .nav-link,
  .nav-item:focus,
  .nav-link.active {
    font-weight: $font-weight-bold;
  }

  &.vertical-tabs-vp {
    flex-direction: column;
  }

  @include mq(991, max) {
    flex-direction: column;
  }

  @include mq(992) {
    border-left: $border-width solid $gray-300;
    display: flex;

    .nav-item {
      background-color: $gray-100;
      border-top: $border-width solid $gray-300;
    }

    .nav-link {
      border-color: transparent $gray-300 $gray-300 transparent;
      border-top-width: $border-width * 4;

      &:focus {
        border-top-width: $border-width * 4;
      }
    }

    .nav-item:hover:not(.active) {
      background-color: #eee;
      border-top-color: transparent;
      color: $blue;
    }

    .nav-item.show .nav-link,
    .nav-item:focus,
    .nav-link.active {
      border-top-width: $border-width * 4;
      border-bottom-color: transparent;
      background-color: $white;
      color: $indigo;
    }
  }
}

.nav-tabs.vp-search-tabs {
  border: none;
  position: relative;

  &:before {
    width: 1px;
    content: "";
    position: absolute;
    background: #d9d9d6;
    left: 0;
    top: 0;
    bottom: 0;
  }

  @include mq(992) {
    &:before {
      display: none;
    }
  }

  @include mq(991, max) {
    margin-bottom: 1rem;
  }

  @include mq(992) {
    border-bottom: $border-width solid $border-color;
  }

  .nav-item {
    background-color: transparent;
    border-top: none;
  }

  .nav-link {
    @include mq(991, max) {
      padding: 0.25rem 0 0.25rem 1rem;
      border: none;
      border-left: $border-width * 4 solid transparent;
    }

    @include mq(992) {
      border-color: transparent;
      border-bottom-width: $border-width * 4;
      font-weight: $font-weight-normal;

      @include hover-focus {
        border-bottom-width: $border-width * 4;
      }
    }

    &.active {
      @include mq(991, max) {
        border-left-color: $indigo;
      }
    }
  }

  .nav-item.show .nav-link,
  .nav-item:focus,
  .nav-item:hover,
  .nav-link.active {
    color: $indigo;

    @include mq(992) {
      border-bottom-width: $border-width * 4;
      border-bottom-color: $indigo;
      background-color: transparent;
      border-right-color: transparent;
    }
  }
  .nav-link.active {
    font-weight: $font-weight-bold;
  }
}

.nav-tabs.navbar-tabs-vp {
  border: none;
  position: relative;
  flex-direction: row;
  justify-content: center;
  border-bottom: $border-width solid $border-color;
  max-width: calc(100% - 0.78rem - 0.78rem);

  .nav-link {
    border-color: transparent;
    border-bottom-width: $border-width * 4;
    font-weight: $font-weight-normal;
    width: 50%;
    text-align: center;

    @include hover-focus {
      border-bottom-width: $border-width * 4;
    }
  }

  .nav-item.show .nav-link,
  .nav-link.active {
    color: $indigo;
    border-bottom-width: $border-width * 4;
    border-bottom-color: $indigo;
    background-color: transparent;
    border-right-color: transparent;
  }
  .nav-link.active {
    font-weight: $font-weight-bold;
  }
}

.tab-content {
  border-top: none;
}

.vertical-tabs-vp {
  width: 100%;
  max-width: 100%;

  .nav-item {
    text-transform: uppercase;
    font-size: $font-size-sm;
    padding: 1.2rem 1.875rem;
    border-left: $border-width * 4 solid transparent;
    border-top: $border-width solid $gray-300;
    border-bottom: $border-width solid $gray-300;
  }

  .nav-item:hover:not(.active) {
    background-color: #eee;
  }

  .nav-item.show .nav-link,
  .nav-item:focus,
  .nav-link.active {
    border-top: $border-width solid $gray-300;
    border-bottom: $border-width solid $gray-300;
    border-left: $border-width * 4 solid $indigo;
    width: calc(100% + 1px);
    z-index: 1;
    border-right: 1px solid $white;
  }

  .nav-link:first-child {
    border-top: none;
  }
}

.vp-gallery-tabs .nav-link {
  max-width: 100%;
  width: 100%;
  text-align: left;

  @include mq(992) {
    width: 163px;
    text-align: center;
  }
}

.vertical-tab-container-vp .nav-tabs {
  display: none;
}

.vertical-tab-container-vp .card-body,
.horizontal-tab-container-vp .card-body {
  padding: 0;

  @include mq(991, max) {
    border-bottom: $border-width solid $border-color;
    padding: 1.25rem;
    border-left: $border-width * 4 solid $indigo;
  }
}

.vertical-tab-container-vp {
  @include mq(992) {
    display: flex;

    .nav-tabs {
      display: flex;
    }

    .card {
      border: none;
    }

    .card .card-header {
      display: none;
    }

    .card .collapse {
      display: block;
    }
  }

  @include mq(991, max) {
    .tab-content > .tab-pane {
      display: block !important;
      opacity: 1;
    }

    .card-header {
      padding: 0;
      background-color: transparent;
    }
  }
}

.vertical-tab-container-vp .tab-content {
  padding: 0;
  border: 1px solid #eeeeee;
  border-top: none;

  @include mq(992) {
    padding: 30px;
  }
}

.horizontal-tab-container-vp {
  @include mq(992) {
    .tab-content {
      border: 1px solid #eee;
      padding: 2rem;
      border-top: none;
    }

    .nav-tabs {
      display: flex;
      flex-wrap: nowrap;
      border-top: 1px solid #eee;
      overflow-x: auto;
      overflow-y: hidden;

      .nav-item {
        display: flex;
        align-items: center;
        justify-content: center;
      }
    }

    .card {
      border: none;
    }

    .card .card-header {
      display: none;
    }

    .card .collapse {
      display: block;
    }
  }

  @include mq(991, max) {
    .tab-content {
      padding: 0;
      border: 1px solid #eeeeee;
      border-bottom: none;
    }
    .nav-tabs {
      display: none;
    }

    .tab-content > .tab-pane {
      display: block !important;
      opacity: 1;
    }

    .card-header {
      padding: 0;
      background-color: transparent;
    }
  }
}

.horizontal-tab-container-vp .card-header a {
  @include mq(991, max) {
    text-transform: uppercase;
    padding: 1.25rem 3rem 1.25rem 1.25rem;
    border-radius: 0;
    width: 100%;
    display: flex;
    cursor: pointer;
    background-color: $gray-100;
    font-size: $font-size-sm;
    border-left: $border-width * 4 solid transparent;
    border-top: $border-width solid $border-color;
    border-bottom: $border-width solid $border-color;
    border-color: transparent transparent #eeeeee transparent;
    position: relative;

    @include mq(992) {
      padding: 1.25rem 2rem;
    }

    &[aria-expanded="true"],
    &:focus,
    &.active {
      border-left: $border-width * 4 solid $indigo;
      color: $indigo;
      background-color: $white;
      font-weight: $font-weight-bold;
      text-decoration: none;
      border-bottom: none;
    }

    &:focus::after,
    &[aria-expanded="true"]::after {
      transform: rotateZ(225deg);
      vertical-align: baseline;
      border-color: $indigo;
    }

    &::after {
      display: inline-block;
      width: 0.5em;
      height: 0.5em;
      margin: -0.3em 0 0 0.4em;
      vertical-align: middle;
      content: "";
      border: 0.3em solid;
      border-width: 0 0.15em 0.15em 0;
      border-color: $blue;
      position: absolute;
      right: 1.25rem;
      top: 50%;
      transform: translateY(-50%) rotateZ(45deg) rotate(0deg);

      @include mq(992) {
        right: 2rem;
      }
    }
  }
}



