.btn:not(.btn-outline-gray) {
  text-transform: uppercase;
}

.btn {
  position: relative;
}

.btn-outline-gray {
  border: $border-width solid $gray-500;
  text-transform: none;

  &:hover,
  &:active,
  &:focus {
    background-color: $gray-300;
    border-color: $gray-300;
  }
}

.btn-outline-inverted-vp {
  border-color: $white;
  color: $white;
}

.btn-primary:hover,
.btn-primary:focus {
  border: $border-width * 2 solid $indigo;
  background-color: $indigo;
  color: $white;
}

.btn-primary.btn-login-vp:hover {
  background-color: transparent;
  color: $indigo;
}

.btn-outline-primary:hover,
.btn-outline-primary:focus {
  border: $border-width * 2 solid $indigo;
  background-color: transparent;
  color: $indigo;
}

.btn-outline-success:hover,
.btn-outline-success:focus {
  border: $border-width * 2 solid $green;
  background-color: transparent;
  color: $green;
}

.btn-outline-inverted-vp:hover,
.btn-outline-inverted-vp:focus {
  border: $border-width * 2 solid $gray-300;
  background-color: transparent;
  color: $gray-300;
}

.btn-outline-danger:hover,
.btn-outline-danger:focus,
.btn-outline-warning:hover,
.btn-outline-warning:focus {
  border: $border-width * 2 solid $red;
  background-color: transparent;
  color: $red;
}

.btn:not(.btn-login-vp) .c-icon.btn-abs-arrow-vp,
.alert-vp .alert-link .c-icon.btn-abs-arrow-vp {
  margin-left: 0.5rem !important;
  margin-right: 0px !important;
  position: absolute;
  opacity: 0;
  right: 22px;
  top: 50%;
  margin-top: -1px;
  width: 1rem;
  height: 1rem;
  min-width: 1rem;
  min-height: 1rem;
  padding: 0;
  border: none;
  border-radius: 0;
  transition: all 0.4s;
  transform: translate(-15px, -50%);
}

.btn:not(.btn-login-vp):hover,
.btn:not(.btn-login-vp):focus {
  .c-icon.btn-abs-arrow-vp {
    opacity: 1;
    border-color: transparent;
    background-color: transparent;
    width: 1rem;
    height: 1rem;
    transform: translate(0, -50%);
  }
}

.btn.px-3:not(.btn-login-vp),
.btn.px-3:not(.btn-login-vp) {
  .c-icon.btn-abs-arrow-vp {
    transform: translate(-5px, -50%);
  }
}

.btn.px-3:not(.btn-login-vp):hover,
.btn.px-3:not(.btn-login-vp):focus {
  .c-icon.btn-abs-arrow-vp {
    transform: translate(10px, -50%);
  }
}

.btn.btn-disabled:hover,
.btn.btn-outline-gray:hover,
.btn.btn-disabled:focus,
.btn.btn-outline-gray:focus {
  .c-icon.btn-abs-arrow-vp {
    display: none;
    opacity: 0 !important;
  }
}

.btn.small-padding-vp {
  .c-icon {
    right: 10px;
  }
}

.btn.btn-login-vp {
  position: relative;
  transform: none;
  border-radius: $border-radius-sm;
  left: 0;
  top: 0;
  right: auto;
  display: flex;
  justify-content: space-between;
  align-items: center;
  white-space: nowrap;
  display: block;

  @include mq(1281) {
    border-radius: 10px 10px 0 0;
    position: absolute;
    transform: rotate(-90deg);
    right: 23px;
    top: 2.6rem;
    left: auto;
    transform-origin: right;
    z-index: $zindex-services-button;

    .c-icon {
      order: 1;
      margin-right: 0.4rem;
    }
  }

  @media (min-width: 1441px) and (max-width: 1920px) {
    position: fixed;
    right: 23px;
  }

  @include mq(767, max) {
    .c-icon {
      margin-right: 0.2rem;
    }
  }
}

.btn.vp-form-submit {
  height: 45px;
  width: 45px;
  border-radius: 50%;
  padding: 13px;
  line-height: 1;

  .c-icon {
    width: 18px;
    height: 18px;
    margin-right: 0;
    right: 2px;
    top: -5px;
  }
}

.btn.vp-form-submit.text-white {
  &:hover,
  &:focus {
    .c-icon {
      fill: $indigo;
    }
  }
}

.btn.vp-form-submit.text-secondary {
  border: $border-width solid $gray-500;
  &:hover,
  &:focus {
    background-color: $blue;

    .c-icon {
      fill: $white;
    }
  }
}

.form-group.search-lg-btn-vp .absolute-submit-vp {
  padding-left: $input-btn-padding-x;
  padding-right: $input-btn-padding-x;
  height: 100%;

  &:hover {
    border-color: $indigo;
  }
}

.btn-directions-vp {
  z-index: $zindex-dropdown;
  display: block;
  width: 35px;
  height: 35px;
  position: absolute;
  right: 20px;
  top: 20px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: $blue;
  transition: $btn-transition;

  &:hover,
  &:focus {
    background-color: $indigo;
  }

  .c-icon {
    margin-right: 0;
  }
}

$aos-distance: 30px !important;

.btn-tabs-collapse,
.btn-accordion-collapse {
  text-transform: uppercase;
  margin-bottom: 1rem;
  color: $indigo;
  position: relative;
  padding-left: 2rem;
  display: flex;
  align-items: center;
  cursor: pointer;
  font-size: 14px;
  background: transparent;
  border: none;

  &.collapsed::before {
    content: "";
    background-image: url("../../../static/usedIcons/icon_accordion_plus.svg");
    width: 20px;
    height: 20px;
    position: absolute;
    left: 0;
  }

  &:not(.collapsed)::before {
    content: "";
    background-image: url("../../../static/usedIcons/icon_accordion_minus.svg");
    width: 20px;
    height: 20px;
    position: absolute;
    left: 0;
  }
}

.btn-tabs-collapse {
  @include mq(992) {
    display: none;
  }
}
