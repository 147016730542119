.swiper {
  width: 100%;
  height: 100%;
  display: flex;
}

.swiper-slide {
  text-align: center;
  font-size: 18px;
  background: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
}

.swiper-slide img {
  display: block;
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.swiper-controls-layout {
  display: none;
  @include mq(576) {
    display: block;
    position: absolute;
    z-index: 2;
    right: 0;
    top: 25%;
  }
    span {
      g {
        color: #003087;
      }

    &:hover {
      g {
        color: white;
      }
    }
  }
}
