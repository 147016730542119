.navbar-light .navbar-collapse {
  flex-direction: column;
  transition: none;
  animation: fadein 0.25s;

  @keyframes fadein {
    from {
      opacity: 0;
    }
    to {
      opacity: 1;
    }
  }

  @include mq(1281) {
    flex-direction: row;
    flex-wrap: nowrap;
  }
}

.navbar-top-vp {
  .nav-link {
    font-size: $font-size-sm;
    font-weight: $font-weight-normal;
    padding-left: $nav-link-padding-x;
    cursor: pointer;

    @include mq(1280, max) {
      padding-top: 0.3rem;
      padding-bottom: 0.3rem;
    }

    @include mq(768) {
      padding-left: 0;
    }
  }

  .nav-item.active .nav-link {
    font-weight: $font-weight-bolder;
    color: rgba($white, 0.75);
  }

  .external-link-vp {
    color: $white;
    display: inline-block;
    position: relative;
    z-index: 1;
  }

  .navbar-collapse {
    flex-basis: auto;
  }

  .navbar-collapse .navbar-nav {
    @include mq(767, max) {
      display: none;
    }
  }

  & > .container.container-body-vp {
    @include mq(767, max) {
      padding: 0;
      display: flex;
      flex-wrap: nowrap;
    }
  }

  .btn-login-vp {
    @include mq(767, max) {
      position: relative;
      z-index: 1;
    }
  }
}

.navbar-light {
  @include mq(1281) {
    background: $white;
    width: 100%;
  }

  & > .container.container-body-vp {
    @include mq(1280, max) {
      width: auto;
      align-items: flex-end;
      justify-content: flex-end;
      margin-right: 0;
      background: $white;
    }
  }
}

.external-link-vp .c-icon {
  height: 17px;
  width: 17px;
}

.navbar-light .navbar-nav {
  display: flex;
  width: 100%;

  @media (min-width: 768px) and (max-width: 1280px) {
    padding-top: 1rem;
    padding-bottom: 1rem;
  }

  .nav-item {
    width: 100%;
    position: relative;
    list-style: none;

    @include mq(1281) {
      width: auto;
      position: static;
    }
  }

  .external-link-vp:before {
    content: "";
    position: absolute;
    left: $nav-link-padding-x;
    right: $nav-link-padding-x;
    top: 0;
    background: $gray-500;
    height: 1px;

    @include mq(1281) {
      position: absolute;
      content: "";
      height: auto;
      width: 1px;
      top: $nav-link-padding-y;
      bottom: $nav-link-padding-y;
      right: auto;
      left: 0;
      background: $blue;
    }
  }

  .external-link-vp ~ .external-link-vp:before {
    display: none;
  }

  .external-link-vp .nav-link {
    @include mq(1281) {
      font-weight: $font-weight-normal;
      padding-right: $nav-link-padding-x;
      max-width: 9rem;
      padding-left: 1rem;
    }
  }

  .nav-link {
    font-weight: $font-weight-bold;
    padding-right: 1vw;
    position: relative;
    min-width: 1%;
    height: 100%;
    width: auto;
    white-space: normal;
    cursor: pointer;
    padding-left: $nav-link-padding-x;

    @include mq(1281) {
      padding-left: 0;
      padding-right: 3vw;
      max-width: calc(10.1rem + 6vw);
    }
  }

  .nav-item:hover > a,
  .nav-item:focus > a,
  .nav-item.active > a,
  .nav-item.show > a,
  .external-link-vp > a:hover,
  .external-link-vp > a:focus {
    color: $indigo;
    position: relative;
    transition: $btn-transition;
  }

  .nav-item > a:before {
    @include mq(1281) {
      height: 4px;
      background-color: transparent;
      width: calc(100% - 3vw);
      position: absolute;
      left: 0;
      bottom: 0;
      right: 0;
      content: "";
      transition: $btn-transition;
    }
  }

  .nav-item:hover > a:before,
  .nav-item.active > a:before,
  .nav-item.show > a:before,
  .external-link-vp > a:hover:before,
  .external-link-vp > a:focus:before {
    @include mq(1281) {
      height: 4px;
      background-color: $indigo;
      width: calc(100% - 3vw);
      position: absolute;
      left: 0;
      bottom: 0;
      right: 0;
      content: "";
      transition: $btn-transition;
    }
  }

  .external-link-vp > a:before,
  .external-link-vp > a:hover:before,
  .external-link-vp > a:focus:before {
    left: 1rem;
    width: calc(100% - 1.56rem);
  }
}

.navbar-dark {
  background: $blue;
}

.navbar-dark .nav-languages-vp,
.nav-languages-vp {
  @include mq(1280, max) {
    padding-right: 1rem;
  }

  .nav-link {
    color: rgba($white, 0.75);
    padding: 0.3rem calc(0.65rem * 0.75);
    font-weight: $font-weight-normal;
    font-size: $font-size-sm;

    @include mq(1281) {
      padding: $nav-link-padding-y calc(#{$nav-link-padding-x} * 0.75);
    }
  }

  .nav-item.active .nav-link {
    font-weight: $font-weight-bolder;
    color: $white;
  }
}

.navbar-brand {
  align-items: center;

  img {
    max-width:100%;
    max-height:100%;
  }
}

.nav-middle-vp {
  padding: 0.81rem 0;

  @include mq(1281) {
    padding: 1.4375rem 0.31rem 0.875rem 0;
  }

  .search-desktop-vp {
    @include mq(767, max) {
      display: none;
    }
  }
}

.nav-languages-vp {
  margin-left: 0.5rem;
  flex-direction: row;
}

.sticky-nav-vp .navbar-light {
  @include mq(1281) {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    z-index: $zindex-sticky;
  }
}

.vp-nav-container {
  background-color: $white;
  border-bottom: $border-width solid $border-color;
  width: 100%;

  &.z-index-lg-vp {
    z-index: $zindex-modal;
  }
}

.sticky-nav-vp .vp-nav-container {
  border: none;
}

.sticky-nav-vp .navbar-light {
  @include mq(1281) {
    box-shadow: $box-shadow-sm;
  }
}

.search-mobile-vp .form-group.has-search input {
  width: 100%;
}

.navbar-light .navbar-collapse {
  @include mq(1280, max) {
    max-height: 100vh;
    overflow: auto;
  }

  @include mq(767, max) {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    background: $white;
  }

  @media (min-width: 768px) and (max-width: 1280px) {
    display: none;
    width: 320px;
    align-self: flex-end;
    position: absolute;
    top: 0;
    right: 0;
    z-index: $zindex-fixed;
    background: $white;

    &.show {
      display: block;
    }

    .navbar-nav {
      flex-wrap: nowrap;
      flex-direction: column;
    }
  }
}

.nav-mobile-container-vp {
  max-height: 100vh;
  overflow-y: scroll;
}

.nav-main-vp {
  display: block;
}

.navbar-top-vp .non-dropdown-items-vp {
  display: none;

  @include mq(1281) {
    display: flex;
  }

  &.navbar-tabs-vp {
    display: flex;
  }
}

.list-unstyled.non-dropdown-items-vp {
  @include mq(1280, max) {
    position: relative;
    padding-left: 1rem;

    &:before {
      width: 1px;
      content: "";
      position: absolute;
      background: rgba($white, 0.3);
      left: 1rem;
      top: calc(#{$dropdown-item-padding-y} * 2);
      bottom: calc(#{$dropdown-item-padding-y} * 2);
    }

    .nav-link {
      font-weight: $font-weight-bolder;
      font-size: $font-size-sm;
      padding-top: $dropdown-item-padding-y;
      padding-bottom: $dropdown-item-padding-y;
    }
  }
}

.navbar-top-vp .top-dropdown-items-vp {
  display: none;

  @include mq(768) {
    display: flex;
  }
}

.navbar-desktop-vp {
  width: 100%;
  flex-direction: row;
  display: none;

  &.show {
    display: flex;
    z-index: $zindex-dropdown;
  }

  .navbar-nav {
    @include mq(1281) {
      flex-direction: row;
      flex-wrap: nowrap;
    }
  }

  &.collapse:not(.show) {
    @include mq(1281) {
      display: flex;
    }
  }
}

.navbar-toggler {
  overflow: hidden;
  margin: 0;
  padding: 0;
  outline: none;
  border: none;
  max-width: 45px;
  width: 45px;
  height: 45px;
  min-width: 45px;
  min-height: 45px;
  box-shadow: $box-shadow-lg;
  transform: rotate(0deg);
  transition: 0.5s ease-in-out;
  cursor: pointer;
  background: $indigo;
  border-radius: 50%;
  z-index: $zindex-modal;

  @include mq(768) {
    box-shadow: $box-shadow-sm;
  }

  @include mq(1281) {
    display: none;
  }

  @include mq(767, max) {
    position: fixed;
    right: 1rem;
    bottom: 1rem;
  }

  span {
    display: block;
    position: absolute;
    height: 2px;
    width: 21px;
    background: $white;
    opacity: 1;
    transform: rotate(0deg);
    transition: 0.25s ease-in-out;

    &:nth-child(even) {
      left: 50%;
      border-radius: 0 9px 9px 0;
    }

    &:nth-child(odd) {
      left: 0;
      border-radius: 9px 0 0 9px;
    }

    &:nth-child(1),
    &:nth-child(2) {
      top: 14px;
      left: 12px;
    }

    &:nth-child(3),
    &:nth-child(4) {
      top: 21px;
      left: 12px;
    }

    &:nth-child(5),
    &:nth-child(6) {
      top: 29px;
      left: 12px;
    }
  }

  &[aria-expanded="true"] span:nth-child(1),
  &[aria-expanded="true"] span:nth-child(6) {
    transform: rotate(45deg);
  }

  &[aria-expanded="true"] span:nth-child(2),
  &[aria-expanded="true"] span:nth-child(5) {
    transform: rotate(-45deg);
  }

  &[aria-expanded="true"] span:nth-child(1) {
    left: 10px;
    top: 21px;
  }

  &[aria-expanded="true"] span:nth-child(2) {
    left: calc(50% - 8.5px);
    top: 21px;
  }

  &[aria-expanded="true"] span:nth-child(3) {
    left: calc(-50% + 8px);
    opacity: 0;
  }

  &[aria-expanded="true"] span:nth-child(4) {
    left: calc(100% - 12px);
    opacity: 0;
  }

  &[aria-expanded="true"] span:nth-child(5) {
    left: 10px;
    top: 25px;
  }

  &[aria-expanded="true"] span:nth-child(6) {
    left: calc(50% - 8.5px);
    top: 25px;
  }
}

.navbar-mobile-header-vp {
  display: flex;
}

.navbar-top-container-vp {
  @include mq(1280, max) {
    padding-right: 0;
  }
}

.navbar-backdrop-vp {
  display: none;
  position: fixed;
  opacity: $modal-backdrop-opacity;
  top: 0;
  left: 0;
  z-index: $zindex-modal-backdrop;
  width: 100vw;
  height: 100vh;
  background-color: $modal-backdrop-bg;
}

.navbar-mobile-header-vp .c-icon {
  width: 18px;
  height: 17px;
}

.nav-collapse-top-menu-vp .accessibility-menu-vp {
  flex: auto;
}

.navbar-tabs-vp {
  &:before {
    display: none;
  }

  @include mq(768) {
    margin-left: 15px;
  }

  &.list-unstyled.non-dropdown-items-vp .nav-link {
    font-weight: $font-weight-normal;
  }

  .nav-item {
    position: relative;
  }

  &.list-unstyled.non-dropdown-items-vp .nav-item.active .nav-link {
    font-weight: $font-weight-bold;
  }

  .nav-item.active:after {
    width: 0;
    height: 0;
    border-style: solid;
    border-width: 6px 9px 0 9px;
    border-color: $blue transparent transparent transparent;
    content: "";
    position: absolute;
    bottom: -6px;
    left: 50%;
    transform: translateX(-50%);

    @include mq(1280, max) {
      bottom: -14px;
    }
  }
}

.navbar-nav-main-wrap-vp {
  @include mq(1281) {
    display: flex;
    align-items: flex-end;

    > ul {
      display: flex;
      justify-content: flex-start;
    }
  }

  & > ul {
    padding-left: 0;
    align-items: flex-end;
  }
}

